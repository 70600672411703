<template>
  <PopUp :show="modal" @hideModal="hideModal">
    <ValidationObserver
      v-slot="{ invalid }"
      tag="div"
      ref="observer"
      class="artist"
      id="scroll-block"
    >
      <header class="artist__header">
        <h3 class="artist__header_title">{{ artist.id ? "Edit" : "Add" }} Creator</h3>
        <div class="artist__header_actions">
          <BaseButton
            v-if="item"
            class="button--sm button--outline button--uppercase"
            text="Delete Creator"
            @click="$emit('removeArtist', artist)"
          ></BaseButton>
          <BaseButton
            class="button--sm button--green button--uppercase"
            text="Save Changes"
            :loading="changesLoading"
            :disabled="invalid"
            @click="saveArtist"
          ></BaseButton>
        </div>
      </header>
      <section class="form">
        <h3 class="form__header">Basic Information</h3>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col form__row_col--side"
            :rules="{ required: true, min: 2, max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="First Name"
              :required="true"
              :validateError="errors[0]"
              v-model="artist.first_name"
              :element="artist.first_name"
              @input="artist.first_name = $event"
            ></BaseInput>
          </ValidationProvider>
          <ValidationProvider
            tag="div"
            class="form__row_col form__row_col--side"
            :rules="{ required: true, min: 2, max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Last Name"
              :required="true"
              :validateError="errors[0]"
              v-model="artist.last_name"
              :element="artist.last_name"
              @input="artist.last_name = $event"
            ></BaseInput>
          </ValidationProvider>
        </div>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col form__row_col--side"
            :rules="{ required: true, path: true, max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Path"
              :required="true"
              :validateError="errors[0] || serverError.slug"
              v-model="artist.slug"
              :element="artist.slug"
              @input="artist.slug = $event"
            >
              <template #text>
                <span class="form-control__simple-text"
                  >Example: {{ `${url}/creators/${artist.slug || "path"}` }}</span
                ></template
              >
            </BaseInput>
          </ValidationProvider>
          <div class="form__row_col form__row_col--side">
            <BaseSelect
              placeholder="Status"
              v-model="artist.status"
              type="status"
              :selected="artist.status"
              :items="[
                { key: '1', title: 'Active' },
                { key: '0', title: 'Inactive' },
              ]"
              @action="artist.status = $event.key"
            ></BaseSelect>
          </div>
        </div>
        <div
          class="form__row form__row--label form__row--bordered form__row--justify-start pb-0"
          :class="{ 'form__row--error': imageValidateError }"
        >
          <span class="form__row_label"
            >Preview Image <span class="form__row_label_required">*</span>
          </span>
          <UploadImage
            class="uploader--lg"
            size="15"
            :image="artist.search_image"
            :loadingImage="loadingImage"
            @upload="upload"
            @editImage="editImage"
            @removeImage="removeImage"
          ></UploadImage>
          <p class="form__row_error">{{ imageValidateError }}</p>
        </div>
        <div class="form__row">
          <MultipleAutocompleteInput
            id="autocomplete-tag"
            :loading="tagsLoading"
            :items="artist.tags"
            :matches="matches"
            placeholder="Tags"
            @addItem="addTag"
            @selectItem="artist.tags = $event"
            @searchMatches="apiGetMatchedTags"
          ></MultipleAutocompleteInput>
        </div>
        <!-- <div class="form__row form__row--bordered form__row--justify-start pb-0">
          <UploadImage
            v-for="(i, idx) in artist.images"
            :id="idx"
            :key="`image-${idx}`"
            :image="i"
            size="15"
            @editImage="editImage"
            @removeImage="removeImage"
          ></UploadImage>
          <UploadImage size="15" @upload="upload"></UploadImage>
        </div> -->
      </section>
      <section class="form">
        <h3 class="form__header">Biography</h3>
        <EditorNew :value="artist.biography" @input="artist.biography = $event"></EditorNew>
      </section>
    </ValidationObserver>
  </PopUp>
</template>
<script>
import PopUp from "@/components/PopUp.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import BaseButton from "@/components/buttons/BaseButton.vue";
import BaseInput from "@/components/inputs/BaseInput.vue";
import BaseSelect from "@/components/inputs/BaseSelect.vue";
import UploadImage from "@/components/UploadImage.vue";
import EditorNew from "@/components/inputs/EditorNew.vue";
import MultipleAutocompleteInput from "@/components/inputs/MultipleAutocompleteInput.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  props: { item: { type: Object, default: null }, modal: { type: Boolean, default: false } },
  components: {
    PopUp,
    ValidationObserver,
    ValidationProvider,
    BaseButton,
    BaseInput,
    BaseSelect,
    UploadImage,
    MultipleAutocompleteInput,
    EditorNew,
  },
  data() {
    return {
      url: process.env.VUE_APP_FRONTEND_URL,
      artist: {
        first_name: "",
        last_name: "",
        slug: "",
        status: null,
        search_image: "",
        biography: "",
        images: [],
        tags: [],
      },
      imageValidateError: null,
      removedImages: [],
      changed: false,
      loadingImage: false,
    };
  },
  created() {
    if (this.item != null) {
      this.artist = JSON.parse(JSON.stringify(this.item));
    }
  },
  beforeDestroy() {
    this.setError(null);
  },
  computed: {
    ...mapGetters("tags", {
      matches: "getMatchedTags",
      tagsLoading: "getChangesLoading",
    }),
    ...mapGetters("artists", {
      serverError: "getServerError",
      changesLoading: "getChangesLoading",
    }),
  },
  watch: {
    artist: {
      handler: function (val, oldVal) {
        if (this.item != null) {
          if (oldVal.status != null) {
            this.changed = true;
          }
        } else {
          this.changed = true;
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("general", ["apiPostUploadImage"]),
    ...mapActions("tags", ["apiPostTag", "apiGetMatchedTags"]),
    ...mapMutations("artists", ["setError"]),
    async upload(file) {
      this.loadingImage = true;
      let image = await this.apiPostUploadImage(file);
      if (image) {
        this.artist.search_image = image;
        this.imageValidateError = null;
      }
      this.loadingImage = false;
    },
    async editImage({ el, idx }) {
      this.loadingImage = true;
      let image = await this.apiPostUploadImage(el);
      if (image) {
        this.artist.search_image = image;
      }
      this.loadingImage = false;
      //let removedEl = this.artist.images.splice(idx, 1, image);
      //this.removedImages.push(removedEl);
    },
    async removeImage(idx) {
      this.loadingImage = true;
      this.imageValidateError = "This field is required";
      this.artist.search_image = "";
      this.loadingImage = false;
      //let removedEl = this.artist.images.splice(idx, 1);
      //this.removedImages.push(removedEl);
    },
    async addTag(val) {
      let data = { title: val };
      let result = await this.apiPostTag(data);
      if (result.success) {
        this.artist.tags.push(result.tag);
        this.$emit("updateTag");
      } else {
        this.$toasted.show(result.error, {
          duration: 2000,
          type: "error",
        });
      }
    },
    hideModal() {
      if (this.changed) {
        this.$swal({
          html: "<h2>Are you sure you want to exit <br/> without saving?</h2>",
          showCancelButton: true,
          cancelButtonText: "Exit without saving",
          confirmButtonText: "Save changes",
        }).then(result => {
          if (result.value) {
            this.$refs.observer.flags.valid ? this.saveArtist() : this.showToast();
          } else this.$emit("hideModal");
        });
      } else this.$emit("hideModal");
    },
    showToast() {
      this.$toasted.show(`Please fill in all required fields`, {
        duration: 2000,
        type: "error",
      });
    },
    saveArtist() {
      let data = {
        ...this.artist,
        tags: this.artist.tags.map(el => {
          return el.id.toString();
        }),
      };
      if (this.artist.id) {
        this.$emit("updateArtist", { data: data, imageForRemove: this.removedImages });
      } else {
        this.$emit("createArtist", { data: data, imageForRemove: this.removedImages });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.artist {
  height: 100%;
  padding: 0 15px;
  overflow-y: auto;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4rem;
    @media only screen and (max-width: $xs) {
      flex-direction: column;
      align-items: flex-start;
    }
    &_title {
      font-size: 3.35rem;
      font-weight: 600;
      line-height: 1.2;
    }
    &_actions {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      @media only screen and (max-width: $xs) {
        justify-content: flex-start;
        width: 100%;
      }
      .button {
        margin-left: 10px;
        @media only screen and (max-width: $xs) {
          margin: 20px 10px 0 0;
        }
      }
    }
  }
}
</style>

